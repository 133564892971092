import { createEventHook } from '~/src/shared/lib';

type NotificationError = (errorMessage: string) => void;
type NotificationSuccess = (message: string) => void;

const NOTIFICATION_ERROR = 'notification:error';
const NOTIFICATION_SUCCESS = 'notification:success';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[NOTIFICATION_ERROR]: NotificationError;
		[NOTIFICATION_SUCCESS]: NotificationSuccess;

	}
}

export const {
	useOnEvent: useOnNotificationError,
	useTriggerEvent: useOnTriggerNotificationError,
} = createEventHook(NOTIFICATION_ERROR);

export const {
	useOnEvent: useOnNotificationSuccess,
	useTriggerEvent: useTriggerNotificationSuccess,
} = createEventHook(NOTIFICATION_SUCCESS);
